import React, { useCallback, useEffect, useState } from "react";
import LayoutAppAuthed from "../components/LayoutAppAuthed";
import { useUser, withUser, AuthAction } from "next-firebase-auth";
import { useRouter } from "next/router";
import FullPageLoader from "../components/FullPageLoader";
import { useQuery } from "react-query";
import { useOrg } from "../components/OrgContext";

const MainIndex = () => {
  const router = useRouter();
  const AuthUser = useUser(); // the user is guaranteed to be authenticated
  // Handles the submit event on form submit.

  const { activeOrg, isLoading, updateActiveOrg, orgs } = useOrg();
  const orgId = activeOrg?.orgId;
  const data = activeOrg;
  


  // if(orgId){
  //   router.push(`/org/${orgId}/website`)
  // }
  
  // Handles the submit event on form submit.
  // const handleSubmit = async (event) => {
  //   // Stop the form from submitting and refreshing the page.
  //   // event.preventDefault();
  //   const token = await AuthUser.getIdToken();
  //   // Get data from the form.
  //   // const data = {
  //   //   name: event.target.name.value,
  //   // };

  //   // Send the data to the server in JSON format.
  //   // const JSONdata = JSON.stringify(data);

  //   // API endpoint where we send form data.
  //   const endpoint = "/api/redirect-main";
  //   // Form the request for sending data to the server.
  //   const options = {
  //     // The method is POST because we are sending data.
  //     method: "POST",
  //     // Tell the server we're sending JSON.
  //     headers: {
  //       "Content-Type": "application/json",
  //       authorization: token,
  //     },
  //     // Body of the request is the JSON data we created above.
  //     // body: JSONdata,
  //   };

  //   // Send the form data to our forms API on Vercel and get a response.
  //   const response = await fetch(endpoint, options);

  //   // Get the response data from server as JSON.
  //   // If server returns the name submitted, that means the form works.
  //   const result = await response.json();

  //   console.log(`result from org/create endpoint`)
  //   console.log(result);

  //   router.push(`/org/${result.orgId}`);
  // };

  // handleSubmit()
  useEffect(() => {
    let isCancelled = false

    // handleSubmit()

    return () => {
      // A quick but not ideal way to avoid state updates after unmount.
      // In your app, prefer aborting fetches:
      // https://developers.google.com/web/updates/2017/09/abortable-fetch
      isCancelled = true
    }
  }, [])

  return (
    <></>
  );
};

MainIndex.getLayout = function getLayout(page) {
  return <>{page}</>;
};

export default withUser({
  whenUnauthedBeforeInit: AuthAction.SHOW_LOADER,
  whenUnauthedAfterInit: AuthAction.REDIRECT_TO_LOGIN,
  LoaderComponent: FullPageLoader,
})(MainIndex);
